import React, { useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroFullscreenSideImages from '../components/hero-fullscreen-side-images';
import SectionStandard from '../components/section-standard';
import ExpertsGrid from '../components/experts-grid';
// services
import styles from './science.module.scss';

const SciencePage = () => {
  const staticData = useStaticQuery(graphql`
    query Science {
      directusScience {
        page_title
        page_description
        hero_title
        hero_description
        hero_image {
          filename_disk
        }
        section_1_title
        section_1_description
        section_1_image_left {
          filename_disk
        }
        section_1_image_right {
          filename_disk
        }
        section_2_title
        section_2_description
        science_experts {
          image {
            filename_disk
          }
          name
          role
        }
        section_3_title
        section_3_description
        section_3_image {
          filename_disk
        }
        section_4_title
        section_4_description
        section_4_image {
          filename_disk
        }
        section_5_title
        section_5_description
        section_5_image {
          filename_disk
        }
      }
    }
  `);

  const { directusScience } = staticData;
  const {
    page_title,
    page_description,
    hero_title,
    hero_description,
    hero_image,
    section_1_title,
    section_1_description,
    section_1_image_left,
    section_1_image_right,
    section_2_title,
    section_2_description,
    science_experts,
    section_3_title,
    section_3_description,
    section_3_image,
    section_4_title,
    section_4_description,
    section_4_image,
    section_5_title,
    section_5_description,
    section_5_image,
  } = directusScience;

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />
      <section className={styles.hero}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.textContainer}>
              <div className={styles.text}>
                {hero_title && <h1>{hero_title}</h1>}
                {hero_title && <hr className={styles.hrDark} />}
                {hero_description && <p className={styles.subheading}>{hero_description}</p>}
              </div>
            </div>
            <img
              className={styles.image}
              src={`${process.env.GATSBY_MEDIA_URL}${hero_image.filename_disk}?w=500`}
              srcSet={`
                  ${process.env.GATSBY_MEDIA_URL}${hero_image.filename_disk}?w=700 700w,
                  ${process.env.GATSBY_MEDIA_URL}${hero_image.filename_disk}?w=1400 1400w,
                  ${process.env.GATSBY_MEDIA_URL}${hero_image.filename_disk}?w=2000 2000w,
                  ${process.env.GATSBY_MEDIA_URL}${hero_image.filename_disk}?w=2800 2800w
                `}
              sizes="100vw"
              alt=""
            />
          </div>
        </div>
      </section>

      <HeroFullscreenSideImages
        title={section_1_title}
        description={section_1_description}
        imageLeft={section_1_image_left}
        imageRight={section_1_image_right}
      ></HeroFullscreenSideImages>

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.experts}>
              <ExpertsGrid experts={science_experts} />
            </div>
            <div className={styles.contentWrapper}>
              <div className={styles.content}>
                <h2>{section_2_title}</h2>
                {section_2_title && section_2_description && <hr className={styles.hrLeftAligned} />}

                {section_2_description && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: section_2_description,
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <SectionStandard
        title={section_3_title}
        description={section_3_description}
        image={section_3_image}
      ></SectionStandard>

      <SectionStandard
        direction="Right"
        title={section_4_title}
        description={section_4_description}
        image={section_4_image}
      ></SectionStandard>

      <SectionStandard
        title={section_5_title}
        description={section_5_description}
        image={section_5_image}
      ></SectionStandard>
    </Layout>
  );
};

export default SciencePage;
